import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from './http-error-handler.service';
import {User} from '../models/user';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {ApprovalInput} from '../models/approval-input';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly handleError: HandleError;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, private auth: AuthService) {
    this.handleError = httpErrorHandler.createHandleError('AuthenticationService');
  }

  getAll(): Observable<User[]> {
    return this.http.get<User[]>(environment.serverUrl + '/shark/users');
  }

  register(user: User): Observable<any> {
    return this.http.post(environment.serverUrl + '/shark/signup', user)
      .pipe(catchError(this.handleError('signup', [])));
  }
  getProfile(): Observable<any> {

    return this.http.get<User>(environment.serverUrl + '/shark/profile');
  }
  approveClient(input: ApprovalInput[]): Observable<any>{

    return this.http.post(environment.serverUrl + '/shark/approve-client', input);
  }
  generateUserReferenceNo(): Observable<any> {
    return this.http.post(environment.serverUrl + '/shark/refno', '');
  }
  generateUserClientSecret(): Observable<any> {
    return this.http.post(environment.serverUrl + '/shark/secret', '');
  }
  updateProfile(input): Observable<any> {
    return this.http.post(environment.serverUrl + '/shark/profile', input);
  }

}
